<template>
  <div>
    <div class="grid-x grid-margin-x loader-container">
      <overlay-loader :loading="$apollo.queries.rows.loading" />
      <div class="cell small-12 medium-12 end">
        <hcc-input v-model="q" class="filter" placeholder="Filter company" />
      </div>
      <div class="cell small-12 medium-12">
        <div class="table">
          <div class="table-title">
            <h1 v-text="$t('dashboard.summary.title')" class="text-subtitle"></h1>
          </div>
          <div class="table-content">
            <table class="stack">
              <thead>
                <tr>
                  <th v-for="(header, index) in headers" v-bind:key="index">
                    {{ $t(header) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in results" v-bind:key="index">
                  <td>{{row[0]}}</td>
                  <td>{{row[1]}}</td>
                  <td>
                    <channel-badge :type="row[2].channelKey" v-tooltip="row[2].channel" />
                  </td>
                  <td>{{row[3]}}</td>
                  <td>{{row[4]}}</td>
                  <td>{{row[5]}}</td>
                  <td>{{row[6]}}</td>
                  <td>{{row[7]}}</td>
                  <td>{{row[8]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import ChannelBadge from '@/components/ChannelBadge.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import Summary from '@/graphql/queries/companies/summary.gql';

export default {
  name: 'SummaryView',
  components: {
    HccInput,
    ChannelBadge,
    OverlayLoader,
  },
  props: {
    filters: Object,
  },
  data() {
    return {
      headers: [
        'dashboard.summary.company',
        'dashboard.summary.sales_agent',
        'dashboard.summary.channel',
        'dashboard.summary.conversations',
        'dashboard.summary.messages',
        'dashboard.summary.wa_conversations',
        'dashboard.summary.wa_fep',
        'dashboard.summary.wa_uic',
        'dashboard.summary.wa_bic',
      ],
      q: '',
      rows: [],
    };
  },
  apollo: {
    rows: {
      query: gql`${Summary}`,
      variables() {
        return {
          start: this.filters?.startDate,
          end: this.filters?.endDate,
        };
      },
      skip() {
        return !this.filters;
      },
      update: ({ summaryCompanies }) => summaryCompanies.sort(
        ([, name], [, nameb]) => name.localeCompare(nameb),
      ).map(([, company, salesAgent, channel, channelKey, ...data]) => [
        company,
        salesAgent,
        {
          channelKey,
          channel,
        },
        ...data,
      ]),
    },
  },
  computed: {
    results() {
      if (this.q) {
        return this.rows.filter(
          ([company]) => new RegExp(`${this.q}`, 'i').test(company),
        );
      }
      return this.rows;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_data-table.scss";
</style>
